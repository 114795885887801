import { graphql, Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React, { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import Layout from "../../components/layout";
import Modal from "../../components/Modal";
export default function Location({ data: { location, suggested, config } }) {
  const data = location?.data;

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <div className="container pt-5">
        <div className="max-w-5xl mx-auto">
          {" "}
          <GatsbyImage fluid={data?.image?.fluid} />
        </div>

        <div className="flex flex-wrap max-w-4xl pt-10 mx-auto md:flex-nowrap lg:pt-20 ">
          <div>
            <div
              className="prose dark:prose-dark max-w-none"
              dangerouslySetInnerHTML={{
                __html: "<h1>" + data?.title?.text + "</h1>" + data?.text?.html,
              }}
            ></div>
            <div className="mt-6">
              {data?.reservations_modal && (
                <a
                  href={config.data.aleno_link}
                  target="_blank"
                  className="inline-block px-5 py-2 font-semibold uppercase bg-blue-light text-blue-dark "
                >
                  Lounge reservieren
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto my-10 lg:my-20">
          <div className="grid gap-10 md:grid-cols-2">
            {data?.lounges?.map((lounge, index) => {
              return (
                <div key={index} className="space-y-3 text-center">
                  <GatsbyImage fluid={lounge?.image?.fluid} />
                  <div
                    className="opacity-80"
                    dangerouslySetInnerHTML={{
                      __html: lounge?.text?.html,
                    }}
                  ></div>{" "}
                  {data?.reservations_modal && (
                    <a
                      href={config.data.aleno_link}
                      target="_blank"
                      className="inline-block px-5 py-2 font-semibold uppercase bg-blue-light text-blue-dark "
                    >
                      Lounge reservieren
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {suggested?.nodes?.length > 0 && (
          <div className="max-w-4xl mx-auto mt-10 prose dark:prose-dark lg:mt-20">
            <h2> Weitere Locations</h2>
          </div>
        )}
        {suggested?.nodes?.length > 0 && (
          <div className="grid max-w-4xl gap-6 py-10 mx-auto md:grid-cols-2 ">
            {suggested?.nodes?.map((item) => {
              return (
                <div className="flex-shrink-0 font-bold bg-white shadow dark:text-white dark:bg-blue-dark ">
                  {" "}
                  <Link to={"/location/" + item.uid}>
                    <GatsbyImage fluid={item?.data?.image?.fluid} />
                  </Link>{" "}
                  <div className="flex items-center justify-between p-3">
                    <span>{item?.data?.title?.text}</span>{" "}
                    <Link
                      to={"/location/" + item.uid}
                      className="inline-block px-3 py-1 ml-auto text-xs uppercase bg-blue-light text-blue-dark"
                    >
                      Mehr{" "}
                      <MdKeyboardArrowRight className="inline-block -mt-0.5" />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      >
        <div className="w-[300px] mx-auto flex-shrink-0  ">
          <iframe className="min-h-[550px]" src={config.data.aleno_link} />
        </div>
      </Modal>
    </Layout>
  );
}

export const query = graphql`
  query ($uid: String) {
    config: prismicConfig {
      data {
        aleno_link
      }
    }
    location: prismicLocation(uid: { eq: $uid }) {
      ...Location
    }
    suggested: allPrismicLocation(filter: { uid: { ne: $uid } }, limit: 2) {
      nodes {
        ...Location
      }
    }
  }
`;
